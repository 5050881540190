<template>
    <div>
        <NavigationCommon activeIndex="6"></NavigationCommon>
        <div class="content">
            <div class="content-left">
                <el-menu :default-active="currentIndex" @Select="menuSelect" >
                    <template v-for="(item, index) in leftMenus" v-bind:key="item.id">
                        <el-menu-item v-if="!item.children || item.children.length == 0" :index="item.code">
                            {{ item.name }}
                        </el-menu-item>
                        <el-sub-menu v-else :index="index">
                            <template #title>{{ item.name }}</template>
                            <el-menu-item v-for="(subItem) in item.children" v-bind:key="subItem.id" :index="subItem.code">
                                {{ subItem.name }}
                            </el-menu-item>
                        </el-sub-menu>
                    </template>
                </el-menu>
            </div>
            <el-card class="content-right">
                <template #header>
                    <div class="card-header">
                        <span class="cardTitle">{{ title }}</span>
                    </div>
                </template>
                <div>
                    <!--基金会介绍-->
                    <img style="width: 100%" v-if="currentIndex === 'aboutUsIntroduceCode'" :src="baseInfoUrl" />
                    <!--基金会章程-->
                    <iframe v-if="currentIndex === 'aboutUsRuleCode'" style="width: 100%; height: 1000px;" :src="ruleUrl"></iframe>
                    <!--基金会荣誉-->
                    <!--基金会资质-->
                </div>
                
            </el-card>
        </div>
        <FooterCommon activeIndex="6"></FooterCommon>
    </div>
</template>
<script>
import NavigationCommon from '@/components/Navigation.vue';
import FooterCommon from '@/components/Footer.vue';
import { listAboutUsMenus, aboutUs } from '@/api/cmsApi'
export default {
    name: 'AboutUs',
    components: {
        NavigationCommon,
        FooterCommon
    },
    data() {
        return {
            currentIndex: 'aboutUsIntroduceCode',
            leftMenus: [],
            title: '',
            baseInfoUrl: '',
            ruleUrl: ''
        }
    },
    methods: {
        init() {
            this.queryLeftMenu()
            this.query()
            this.menuSelect(this.currentIndex)
        },
        query() {
            const param = {"type": this.currentIndex}
            aboutUs(param).then(res => {
                if (res.code === 200) {
                    if (res.data) {
                        this.baseInfoUrl = res.data.linkUrl
                        this.ruleUrl = res.data.linkUrl
                    }
                }
            })
        },
        queryLeftMenu() {
            listAboutUsMenus().then(res => {
                if (res.code === 200) {
                    this.leftMenus = res.data
                    // 默认选中第一个
                    this.currentIndex = this.leftMenus ? this.leftMenus[0].code : ''
                    this.title = this.leftMenus ? this.leftMenus[0].name : ''
                }
            })
        },
        handleCurrentChange(currentPage) {
            this.pageNo = currentPage; //每次点击分页按钮，当前页发生变化
            this.query()
        },
        menuSelect(index) {
            for (var item in this.leftMenus) {
                if (index === this.leftMenus[item].code) {
                    this.title = this.leftMenus[item].name
                } else if (this.leftMenus[item].children) {
                    for (var subItem in this.leftMenus[item].children) {
                        if (index === this.leftMenus[item].children[subItem].code) {
                            this.title = this.leftMenus[item].children[subItem].name
                        }
                    }
                }
            }
            this.currentIndex = index
            this.pageNo = 1
            this.query()
        }
    },
    created() {
        this.init()
    }
}
</script>
<style scoped>
    .title {
        margin-top: 20px;
        font-size: xx-large;
        font-weight: bold;
        color: red;
        text-align: center;
    }
    .cardTitle {
        font-weight: bold;
        font-size: x-large;
        color: red;
    }
    .content {
        max-width: 1140px;
        margin: 0 auto;
        margin-top: 20px;
        min-height: 800px;
        height: 1500px;
    }
    .page {
        margin-top: 10px;
    }
    .pageContent {
        justify-content: center;
    }
    .dongtaiRow {
        height: 240px;
        display: flex;
        margin-top: 20px;
    }
    .dongtaiImg {
        width: 32%;
    }
    .dongtaiImgTab {
        width: 245px;
        height: 170px;
    }
    .dongtaiTitle {
        width: 60%;
        font-size: x-large;
        text-align: left;
        margin-left: 30px;
        margin-top: 50px;
    }
    .titleContent {
        width: 60%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .titleDate {
        font-size: 16px;
    }
    a {
        text-decoration: none;
    }
    .content-left {
        height: 100%;
        width: 20%;
        float: left;
    }
    .content-right {
        height: 100%;
        width: 79%;
        float: right;
    }
</style>