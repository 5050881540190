import request from '../utils/request'
// 首页轮播图
export function listSlide() {
    return request({
        url: '/cms/listSlide',
        method: 'get'
    })
}
// 首页数据
export function indexData() {
    return request({
        url: '/cms/indexData',
        method: 'get'
    })
}
// 公益项目
export function charityProject(params) {
    return request({
        url: '/cms/charityProject',
        method: 'get',
        params: params
    })
}
// 机构动态
export function organStatus(params) {
    return request({
        url: '/cms/organStatus',
        method: 'get',
        params: params
    })
}
// 行业资讯
export function industryNews(params) {
    return request({
        url: '/cms/industryNews',
        method: 'get',
        params: params
    })
}
// 信息披露
export function informationShow(params) {
    return request({
        url: '/cms/informationShow',
        method: 'get',
        params: params
    })
}

// 详情
export function detail(params) {
    return request({
        url: '/cms/detail',
        method: 'get',
        params: params
    })
}
// 查询信息披露模块的菜单
export function listInformationMenus() {
    return request({
        url: '/cms/listInformationMenus',
        method: 'get'
    })
}

// 查询关于我们模块的菜单
export function listAboutUsMenus() {
    return request({
        url: '/cms/listAboutUsMenus',
        method: 'get'
    })
}

// 查询关于我们模块的菜单
export function aboutUs(param) {
    return request({
        url: '/cms/aboutUs',
        method: 'get',
        params: param
    })
}