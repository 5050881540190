<!--机构动态-->
<template>
    <div>
        <NavigationCommon activeIndex="3"></NavigationCommon>
        <div class="content">
            <el-card>
                <template #header>
                    <div class="card-header">
                        <span class="cardTitle">机构动态</span>
                    </div>
                </template>
                <div class="dongtaiRow">
                    <ul v-for="item in tableData" :key="item.id">
                        <a v-if="item.content" :href="'/detail?id=' + item.id" target="_blank">
                            <li class="title">{{ item.title }}</li>
                            <li class="publiskDate">{{ item.publishDate }}</li>
                        </a>
                        <a v-if="!item.content" :href="item.linkUrl" target="_blank">
                            <li class="title">{{ item.title }}</li>
                            <li class="publiskDate">{{ item.publishDate }}</li>
                        </a>
                    </ul>
                </div>
                <div class="page">
                    <el-pagination background layout="prev, pager, next" class="pageContent" @current-change="handleCurrentChange" :page-size="pageSize" :total="total" :current-page="pageNo"/>
                </div>
            </el-card>
        </div>
        <FooterCommon activeIndex="3"></FooterCommon>
    </div>
</template>

<script>
import NavigationCommon from '@/components/Navigation.vue';
import FooterCommon from '@/components/Footer.vue';
import { organStatus } from '@/api/cmsApi';
export default {
    name: 'OrganDynamic',
    components: {
        NavigationCommon,
        FooterCommon
    },
    data() {
        return {
            tableData: [],
            pageNo: 1,
            pageSize: 5,
            total: 0
        }
    },
    methods: {
        init() {
            this.query()
        },
        query() {
            let param = {'pageNo': this.pageNo, 'pageSize': this.pageSize}
            organStatus(param).then(res => {
                if (res.code === 200) {
                    this.tableData = res.data.records
                    this.total = res.data.total
                }
            })
        },
        handleCurrentChange(currentPage) {
            this.pageNo = currentPage; //每次点击分页按钮，当前页发生变化
            this.query()
        }
    },
    created() {
        this.init()
    }
}

</script>
<style scoped>
    .cardTitle {
        font-weight: bold;
        font-size: xx-large;
        color: red;
    }
    .content {
        max-width: 1140px;
        margin: 0 auto;
    }
    .page {
        margin-top: 10px;
    }
    .pageContent {
        justify-content: center;
    }
    .titleDate {
        float: right;
    }
    .titleContent {
        float: left;
    }
    ul {
        list-style-type: none;
        text-align: justify;
        display: flex;
        justify-content: space-between;
    }
    li {
        display: inline;
        break-inside: avoid;
        width: calc(50% - 10px); 
        margin-bottom: 10px;
    }
    .title {
        text-align: left;
        padding-right: 10px; 
    }
    .publishDate {
        text-align: right;
        padding-left: 10px;
    }
</style>