import IndustryInformation from '@/views/IndustryInformation.vue';
import AboutUs from '@/views/AboutUs.vue';
import {createRouter, createWebHistory} from 'vue-router';
import CharityProject from '@/views/CharityProject.vue';
import OrganDynamic from '@/views/OrganDynamic.vue';
import IndexPage from '@/views/IndexPage.vue';
import InformationShow from '@/views/InformationShow.vue';
import DetailView from '@/views/DetailView.vue';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            name: '首页',
            path: '/',
            component: IndexPage
        },
        {
            name: '公益项目',
            path: '/charityProject',
            component: CharityProject
        },
        {
            name: '机构动态',
            path: '/organDynamic',
            component: OrganDynamic
        },
        {
            name: '行业动态',
            path: '/industryInformation',
            component: IndustryInformation
        },
        {
            name: '信息披露',
            path: '/informationShow',
            component: InformationShow
        },
        {
            name: '关于我们',
            path: '/aboutUs',
            component: AboutUs
        },
        {
            name: '详情页',
            path: '/detail',
            component: DetailView
        }
    ]
})
export default router;