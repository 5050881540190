<template>
    <div>
        <div class="menu">
            <el-menu :default-active="currentIndex" mode="horizontal" :ellipsis="false" @Select="handleSelect" >
                <el-menu-item index="0">
                    <img class="logo" src="/logo.png" />
                </el-menu-item>
                <div class="flex-grow" />
                <el-menu-item index="1"><RouterLink to="/">首页</RouterLink></el-menu-item>
                <el-menu-item index="2"><RouterLink to="/charityProject">公益项目</RouterLink></el-menu-item>
                <el-menu-item index="3"><RouterLink to="/organDynamic">机构动态</RouterLink></el-menu-item>
                <el-menu-item index="4"><RouterLink to="/industryInformation">行业资讯</RouterLink></el-menu-item>
                <el-menu-item index="5"><RouterLink to="/informationShow">信息披露</RouterLink></el-menu-item>
                <el-menu-item index="6"><RouterLink to="/aboutUs">关于我们</RouterLink></el-menu-item>
            </el-menu>
        </div>
        <el-carousel :interval="5000" arrow="always" height="500px">
            <el-carousel-item v-for="item in slideImages" :key="item">
                <img :src="item.mainImgUrl" width="100%" />
            </el-carousel-item>
        </el-carousel>
    </div>

</template>

<script>
import { listSlide } from '@/api/cmsApi';
export default {
    name: 'NavigationCommon',
    props: {
        activeIndex: String
    },
    data() {
        return {
            currentIndex: this.activeIndex,
            slideImages: []
        }
    },
    methods: {
        handleSelect(key) {
            this.currentIndex = key;
        },
        init() {
            // 查询轮播图
            listSlide().then(res => {
                if (res.code === 200) {
                    this.slideImages = res.data
                }
            })
            
        }
    },
    created() {
        this.init()
    }
}
</script>
<style>
.flex-grow {
  flex-grow: 1;
}
.menu {
    max-width: 1140px;
    height: 88px;
    margin: 0 auto;
}
.logo {
    height: 100px;
    width: 100%;
}
.el-carousel__item h3 {
  color: #475669;
  opacity: 0.75;
  line-height: 550px;
  margin: 0;
  text-align: center;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
a {
    text-decoration: none;
}
</style>