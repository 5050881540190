<!--公益项目-->
<template>
    <div>
        <NavigationCommon activeIndex="2"></NavigationCommon>
        <div class="content">
            <el-card>
                <template #header>
                    <div class="card-header">
                        <span class="cardTitle">公益项目</span>
                    </div>
                </template>
                <el-table :data="tableData" style="width: 100%" :show-header="false">
                    <el-table-column prop="title" label="title">
                        <template #default="{ row }">
                            <a :href="row.linkUrl" target="_blank">{{ row.title }}</a>
                        </template>
                    </el-table-column>
                    <el-table-column prop="publishDate" label="publishDate" width="180" />
                </el-table>
                <div class="page">
                    <el-pagination background layout="prev, pager, next" class="pageContent" @current-change="handleCurrentChange" :total="total" :current-page="pageNo" :page-size="pageSize"/>
                </div>
            </el-card>
        </div>
        <FooterCommon activeIndex="2"></FooterCommon>
    </div>
</template>
<script>
import NavigationCommon from '@/components/Navigation.vue';
import FooterCommon from '@/components/Footer.vue';
import { charityProject } from '@/api/cmsApi';
export default {
    name: 'CharityProject',
    components: {
        NavigationCommon,
        FooterCommon
    },
    data() {
        return {
            tableData: [],
            pageNo: 1,
            pageSize: 10,
            total: 0
        }
    },
    methods: {
        init() {
            this.query()
        },
        query() {
            let param = {'pageNo': this.pageNo, 'pageSize': this.pageSize}
            charityProject(param).then(res => {
                if (res.code === 200) {
                    this.tableData = res.data.records
                    this.total = res.data.total
                    console.log(this.total)
                    console.log(this.pageNo)
                    console.log(this.pageSize)
                }
            })
        },
        handleCurrentChange(currentPage) {
            this.pageNo = currentPage; //每次点击分页按钮，当前页发生变化
            this.query()
        }
    },
    created() {
    },
    mounted() {
        this.init()
    }
}
</script>

<style scoped>
    .cardTitle {
        font-weight: bold;
        font-size: xx-large;
        color: red;
    }
    .content {
        max-width: 1140px;
        margin: 0 auto;
    }
    .page {
        margin-top: 10px;
    }
    .pageContent {
        justify-content: center;
    }
</style>