<!--行业资讯-->
<template>
    <div>
        <NavigationCommon activeIndex="4"></NavigationCommon>
        <div class="content">
            <el-card>
                <template #header>
                    <div class="card-header">
                        <span class="cardTitle">行业资讯</span>
                    </div>
                </template>
                <div>
                    <div class="dongtaiRow" v-for="item in tableData" :key="item.id">
                        <div class="dongtaiImg">
                            <img :src="item.mainImgUrl" class="dongtaiImgTab"/>
                        </div>
                        <div class="dongtaiTitle">
                            <a :href="item.linkUrl" target="_blank">
                            <p><span class="titleContent">{{ item.title }}</span></p>
                            <p><span class="titleDate">{{ item.publishDate }}</span></p>
                            </a>
                        </div>
                    </div>
                    <div class="page">
                        <el-pagination background layout="prev, pager, next" class="pageContent" @current-change="handleCurrentChange" :page-size="pageSize" :total="total" :current-page="pageNo"/>
                    </div>
                </div>
                
            </el-card>
        </div>
        <FooterCommon activeIndex="4"></FooterCommon>
    </div>
</template>

<script>
import NavigationCommon from '@/components/Navigation.vue';
import FooterCommon from '@/components/Footer.vue';
import { industryNews } from '@/api/cmsApi';
export default {
    name: 'IndustryInformation',
    components: {
        NavigationCommon,
        FooterCommon
    },
    data() {
        return {
            tableData: [],
            pageNo: 1,
            pageSize: 5,
            total: 0
        }
    },
    methods: {
        init() {
            this.query()
        },
        query() {
            let param = {'pageNo': this.pageNo, 'pageSize': this.pageSize}
            industryNews(param).then(res => {
                if (res.code === 200) {
                    this.tableData = res.data.records
                    this.total = res.data.total
                }
            })
        },
        handleCurrentChange(currentPage) {
            this.pageNo = currentPage; //每次点击分页按钮，当前页发生变化
            this.query()
        }
    },
    created() {
        this.init()
    }
}
</script>

<style scoped>
    .cardTitle {
        font-weight: bold;
        font-size: xx-large;
        color: red;
    }
    .content {
        max-width: 1140px;
        margin: 0 auto;
    }
    .page {
        margin-top: 10px;
    }
    .pageContent {
        justify-content: center;
    }
    .dongtaiRow {
        height: 240px;
        display: flex;
        margin-top: 20px;
    }
    .dongtaiImg {
        width: 32%;
    }
    .dongtaiImgTab {
        width: 245px;
    }
    .dongtaiTitle {
        width: 60%;
        font-size: x-large;
        text-align: left;
        margin-left: 30px;
        margin-top: 50px;
    }
    .titleContent {
        width: 60%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .titleDate {
        font-size: 16px;
    }
    a {
        text-decoration: none;
    }
</style>