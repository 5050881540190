<!--详情页-->
<template>
    <div>
        <NavigationCommon activeIndex="4"></NavigationCommon>
        <div class="content">
            <el-card>
                <template #header>
                    <div class="card-header">
                        <span class="cardTitle">{{ title }}</span>
                    </div>
                    <div class="card-header-info">
                        <span>发布日期：</span><span>{{ publishDate }}</span>
                    </div>
                </template>
                <div class="content" v-html="content">
                </div>
            </el-card>
        </div>
        <FooterCommon activeIndex="4"></FooterCommon>
    </div>
</template>

<script>
import NavigationCommon from '@/components/Navigation.vue';
import FooterCommon from '@/components/Footer.vue';
import { detail } from '@/api/cmsApi'
export default {
    name: 'DetailView',
    components: {
        NavigationCommon,
        FooterCommon
    },
    data() {
        return {
            publishDate: '',
            content: '',
            title: ''
        }
    },
    methods: {
        init() {
            this.query()
        },
        query() {
            let param = {'id': this.$route.query.id}
            detail(param).then(res => {
                if (res.code === 200) {
                    this.publishDate = res.data.publishDate
                    this.content = res.data.content
                    this.title = res.data.title
                }
            })
        }
    },
    created() {
        this.init()
    }
}
</script>

<style scoped>
    .cardTitle {
        font-weight: bold;
        font-size: xx-large;
        color: red;
    }
    .content {
        max-width: 1140px;
        margin: 0 auto;
    }
    .page {
        margin-top: 10px;
    }
    .pageContent {
        justify-content: center;
    }
    .dongtaiRow {
        height: 240px;
        display: flex;
        margin-top: 20px;
    }
    .dongtaiImg {
        width: 32%;
    }
    .dongtaiImgTab {
        width: 245px;
    }
    .dongtaiTitle {
        width: 60%;
        font-size: x-large;
        text-align: left;
        margin-left: 30px;
        margin-top: 50px;
    }
    .titleContent {
        width: 60%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .titleDate {
        font-size: 16px;
    }
    a {
        text-decoration: none;
    }
    .card-header-info {
        float: right;
        font-weight: 600;
    }
    .contentTitle {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 20px;
    }
</style>